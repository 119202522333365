<template>
  <div>
    <!-- 弹窗部分 -->
    <el-dialog title="新增施工人队" :visible.sync="dialogVisible" width="40%">
      <!-- 表格部分 -->
      <el-form :model="form" label-width="100px" style="width: 90%; padding: 3%;">
        <el-form-item label="施工队名称">
          <el-input v-model="form.teamName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="负责人名称">
          <el-input v-model="form.headName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="负责人联系方式">
          <el-input v-model="form.headPhone" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>


      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      selectedItems: [],
      dialogVisible: false
    };
  },
  methods: {
    init() {
      this.dialogVisible = true
      this.getData()
    },
    async getData() {
      let condition = {
        status: 2,
        inOutFlag: 1,
        ...this.form,
      };
      let res = await this.$http.post("/userInfo/list", {});
      if (res.data.code == 200) {
        this.totol = res.data.data.count;
        this.tableData = res.data.data.data;
      }
    },
    async add() {
      let data = {
        ...this.form,
        agentCode: JSON.parse(localStorage.getItem('userInfo')).userInfo.userCode,
        agentName: JSON.parse(localStorage.getItem('userInfo')).userInfo.agentName
      }
      let res = await this.$http.post("/constructionTeam/add", data);
      if (res.data.code == 200) {
        this.$notify({
          title: '成功',
          message: '新增成功',
          type: 'success'
        });
        this.dialogVisible = false
        this.$emit('refrash')
      }
    },
  }
};
</script>
