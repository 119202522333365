<template>
  <div class="container bgblue">
    <div class="header">
      <el-row>
        <el-form :model="form" class="demo-form-inline" label-width="80px">
          <el-col :span="6">
            <el-form-item label="订单号:">
              <el-input v-model="form.userName" placeholder="请输入订单号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="金融单号:">
              <el-input v-model="form.orderCode" placeholder="请输入金融单号" clearable></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="用户姓名:">
              <el-input v-model="form.bankingNumber" placeholder="请输入用户姓名" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="20px">
              <el-button type="primary" icon="el-icon-search" @click="onSubmit">查询</el-button>
              <el-button icon="el-icon-refresh" @click="onReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <div class="table">
      <el-button type="primary" style="margin-bottom: 5px;" @click="toAdd">新 增</el-button>
      <el-table :data="tableData" style="width: 100%" border height="calc(100vh - 280px)">
        <el-table-column align="center" prop="date" label="操作" width="100" fixed>
          <template v-slot="{ row }">
            <el-button type="text" size="mini" class="btn-blue" @click="addPersonnel(row)">新增人员</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="teamCode" label="施工队编号" width="180">
        </el-table-column> -->
        <el-table-column align="center" prop="teamName" label="施工队名称" width="180">
        </el-table-column>
        <el-table-column align="center" prop="headName" label="负责人名称">
        </el-table-column>
        <el-table-column align="center" prop="headPhone" label="负责人联系方式">
        </el-table-column>
        <el-table-column align="center" prop="agentName" label="代理商名称">
        </el-table-column>
        <el-table-column align="center" prop="createDate" label="创建时间" width="180">
        </el-table-column>
        <el-table-column align="center" prop="updateDate" label="修改时间" width="180">
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="params.currPage" :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="totol">
        </el-pagination>
      </div>
    </div>

    <!-- 弹框 -->
    <add ref="addRef" @refrash="getData"></add>
    <addPersonnel ref="personnelRef"></addPersonnel>
  </div>
</template>

<script>
import add from "./dialog/construction/add";
import addPersonnel from "./dialog/construction/addPersonnel";
export default {
  components: { add, addPersonnel },
  data() {
    return {
      tableData: [],
      detailList: [], //详情表格
      datailObj: {}, //详情对象
      showDialog: false,
      form: {
        userName: "",
        orderCode: "",
        bankingNumber: "",
        status: null,
        beginTime: "",
        endTime: "",
        date: "",
      },
      params: {
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 10,
      },
      totol: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    toAdd() {
      this.$refs.addRef.init()
    },
    onSubmit() {
      this.params.currPage = 1;
      this.getData();
    },
    async getData() {
      let condition = {
        status: 2,
        inOutFlag: 1,
        ...this.form,
      };
      const {
        data: { data },
      } = await this.$http.post("constructionTeam/list", {
        condition,
        ...this.params,
      });
      this.totol = data.count;
      this.tableData = data.data;
    },
    // 新增人员
    addPersonnel(row) {
      this.$refs.personnelRef.init(row)
    },
    // 查看明细
    async handelDetail(row) {
      this.datailObj = row;
      this.showDialog = true;
      const {
        data: { data },
      } = await this.$http.post("/storeRecordBind/queryList", {
        inOutId: row.id,
      });
      this.detailList = data;
    },
    onReset() {
      this.form.bankingNumber = "";
      this.form.date = "";
      this.form.orderCode = "";
      this.form.status = "";
      this.form.userName = "";
      this.getData();
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.params.currPage = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 5px;
  box-sizing: border-box;

  ::v-deep .el-input__inner {
    width: 100% !important;
  }

  ::v-deep .el-dialog__header {
    background-color: #f6f8fb;
  }

  ::v-deep .el-select {
    width: 100% !important;
  }

  ::v-deep .el-form-item__label {
    font-size: 13px;
  }

  .el-table {
    margin-top: 0px !important;
  }

  .header {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    padding-bottom: 0px !important;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px #ccc;

  }

  .table {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;

    box-sizing: border-box;
    margin-top: 10px;
    height: calc(100vh - 190px);

    ::v-deep .el-button--primary.is-plain {
      height: 20px !important;
      line-height: 6px !important;
    }

    ::v-deep .el-table td {
      padding: 0.5px 0;
      box-sizing: border-box;
    }
  }

  .page {
    width: 100%;
    text-align: end;

    ::v-deep .el-input__inner {
      height: 25px !important;
    }

  }

  #dialog {
    // ::v-deep .el-button {
    //   height: 35px !important;
    // }

    .img {
      width: 150px;
      height: 150px;
      margin: 10px;
    }
  }
}

#pdf {
  background-color: #fcfdfe !important;
  padding: 10px;
  box-sizing: border-box;
}

::v-deep .el-dialog__body {
  padding: 0 0 30px 0 !important;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: #f59a23 !important;
}
</style>
