<template>
  <div class="box">
    <el-drawer title="新增人员" :visible.sync="drawer" direction="rtl" size="60%">
      <el-form style="margin-right: 20px;" :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <div style="display: flex; justify-content: flex-end; margin-bottom: 10px; margin-right: 10px;">
          <el-button type="primary" @click="SubmitEvent(0)">保存</el-button>
        </div>
      </el-form>
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 94%; margin: 10px 3%"
        @selection-change="handleSelectionChange" border fit height="calc(100vh - 250px)" highlight-current-row>
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column property="userCode" align="center" label="用户编号" width="180">
        </el-table-column>
        <el-table-column property="name" align="center" label="姓名" width="120">
        </el-table-column>
        <el-table-column property="sex" align="center" label="性别">
          <template slot-scope="scope">{{ scope.row.status == 1 ? '男' : '女' }}</template>
        </el-table-column>
        <el-table-column property="phone" align="center" label="电话">
        </el-table-column>
      </el-table>
      <div class="page" style="display: flex; justify-content: flex-end;margin-right: 20px;">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="params.currPage" :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="totol">
        </el-pagination>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      ruleForm: {
      },
      tableData: [],
      selectedItems: [],
      rules: {
        assessmentMonth: [
          { required: true, message: "请输入考核类型", trigger: "blur" },
        ],
        typeAssessment: [
          { required: true, message: "考核类别", trigger: "change" },
        ],
        plannedUpvolume: [
          { required: true, message: "请输入考核类型", trigger: "blur" },
        ],
      },
      params: {
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 50,
      },
      totol: 0,
      parentTrans: {},
      constructionTeam: {},
      echoList: [],
    };
  },
  created() {
    this.getData()
  },
  methods: {
    init(row) {
      this.drawer = true;
      this.parentTrans = row
      this.getData()
      this.queryList()
      // this.setSelectedRows()
    },
    handleSelectionChange(val) {
      this.selectedItems = val;
    },
    async getData() {

      let res = await this.$http.post("/userInfo/list", { ...this.params, });
      if (res.data.code == 200) {
        this.totol = res.data.data.count;
        this.tableData = res.data.data.data;
      }
    },
    changePersonCode(val) {
      this.ruleForm.PersonCode = val;
      this.getData()
    },
    async queryList() {
      let res = await this.$http.post("/constructionMember/queryList", {
        teamCode: this.parentTrans.teamCode,
      });
      if (res.data.code == 200) {
        let data = res.data.data.map(item => {
          return item.memberCode
        })
        this.$nextTick(()=>{
          this.selectedItems =  this.tableData.filter(item=> data.includes(item.userCode))
        this.setSelectedRows()
        })
      }
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.params.currPage = val;
      this.getData();
    },
    setSelectedRows() {
      this.$nextTick(() => {
        console.log(this.$refs.multipleTable
          , 'this.$refs.multipleTable')
        this.$refs.multipleTable.clearSelection();
        this.selectedItems.forEach((item) => {
          this.$refs.multipleTable.toggleRowSelection(item, true);
        });
      });
    },
    async SubmitEvent() {
      let data = this.selectedItems.map(item => {
        let t = {}
        t.teamCode = this.parentTrans.teamCode
        t.teamName = this.parentTrans.teamName
        t.memberCode = item.userCode
        t.memberName = item.name
        t.identityType = item.identityType
        t.memberPhone = item.phone
        t.jobNo = item.jobNo
        return t
      })
      let res = await this.$http.post("/constructionMember/saveBatch", data);
      if (res.data.code == 200) {
        this.$notify({
          title: '成功',
          message: '新增成功',
          type: 'success'
        });
      }
    }
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 50%;

  // /deep/ .el-form-item {
  //     margin-bottom: 16px !important;
  // }
}
</style>
